import { notification, } from 'antd'

const openNotification = (type, message, description) => {
  notification[type]({
    message: message,
    description:
      description ? description : '',
    duration: 8,
  })
}

export default openNotification
