/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react'
import {
  Link, Outlet,
} from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  Layout, Menu, Button, Typography
} from 'antd'

const { Text } = Typography

import { FaPlaneDeparture, FaProjectDiagram, FaCog } from 'react-icons/fa'
import { LogoutOutlined } from '@ant-design/icons'
import navState from '../atoms/NavState'
import { authState } from '../atoms/AuthState'

const {
  Content, Footer, Sider,
} = Layout
// const { SubMenu } = Menu

const envText = () => {
  if (process.env.REACT_APP_STATUS === 'LOCAL') {
    return (<Text strong type="danger">Warning! Local development environment</Text>)
  }
  if (process.env.REACT_APP_STATUS === 'DEV') {
    return (<Text strong type="danger">Warning! Development environment</Text>)
  }
  if (process.env.REACT_APP_STATUS === 'PROD') {
    return (<Text strong>PROD</Text>)
  }
}

const Nav = ({ auth }) => {
  // eslint-disable-next-line no-unused-vars
  const navStateStatus = useRecoilValue(navState)
  const setNavStateStatus = useSetRecoilState(navState)
  const setAuthState = useSetRecoilState(authState)

  const setValue = (value) => {
    setNavStateStatus({ menu: value, ...navStateStatus })
    console.log(value)
  }

  const handleLogout = async () => {
    await auth.signOut({ global: false })
    console.log('Logout')
    setAuthState({
      auth: false,
      signInUserSession: {},
    })
  }

  const setSubMenu = () => {
    setNavStateStatus({ ...navStateStatus, submenu: 'sub1' })
  }

  const items = [
    { label: (<Link to="/" onClick={() => setValue(1)}>Approvals</Link>), key: '1', icon: <FaPlaneDeparture /> },
    { label: (<Link to="/routes" onClick={() => setValue(2)}>Routes</Link>), key: '2', icon: <FaProjectDiagram /> },
    {
      label: 'Manage',
      key: 'sub1',
      icon: <FaCog />,
      onTitleClick: () => {
        setSubMenu()
      },
      children: [
        { label: (<Link to="/airports" onClick={() => setValue(3)}>Airports</Link>), key: '3' },
        { label: (<Link to="/aircrafts" onClick={() => setValue(4)}>Aircrafts</Link>), key: '4' },
      ],
    },
    { label: (<Button
      ghost
      icon={<LogoutOutlined />}
      onClick={handleLogout}
      style={{ margin: '16px' }}
    >Logout</Button>),
    key: 'logout',
    style: { position: 'absolute', bottom: 0, left: 0 }
    },
  ]

  return (
    <>
      <Layout>
        <Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <Menu items={items} theme="dark" selectedKeys={[navStateStatus.menu.toString()]} openKeys={navStateStatus.submenu ? [navStateStatus.submenu] : []} mode="inline" />
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: 200, minHeight: '100vh' }} >
          <Content className="site-layout-background">
            <div style={{ padding: 12, minHeight: 360 }}>
              <Outlet />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center', position: 'sticky', bottom: '0' }}>Approval App version 1.0 - {envText()}</Footer>
        </Layout>
      </Layout>
    </>
  )
}

export default Nav
