/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import {
  useParams,
} from 'react-router-dom'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { format } from 'date-fns'

import {
  Table, Descriptions, Form, Button, Modal, Upload,
  Divider, Empty, Row, Col, Collapse,
  Typography, Input, Popconfirm
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import apiService from '../services/apiService'

import FormGen from './forms/FormGen'
import { Clone, NewDraft } from './NewApproval'
import ApprovalForm from './ApprovalForm'

import RunwaysTable from './Runway'
import notificationHandler from './notificationHandler'

import {
  initState, ACTList, APTList, APTQuestions, RWYQuestions,
} from '../atoms/initAtom'

import { staticQ } from './staticQuestions'


const { Panel } = Collapse
const { Title } = Typography

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
}

const isActive = (data) => {
  if (data?.version) {
    if (data.version !== null && data.revokedAt === null) {
      return true
    }
  }
  return false
}

const flatJson = (data) => {
  console.log('flatJson id:', data.id)
  let jsonApproval
  if (data?.jsonApproval?.act) {
    jsonApproval = data.jsonApproval
  } else {
    jsonApproval = JSON.parse(data.jsonApproval)
  }
  //const jsonApproval = data.jsonApproval

  const tempData = { ...data }
  delete tempData.jsonApproval
  const result = {
    ...tempData,
    ...jsonApproval
  }

  delete result.rwys
  delete result.amts
  // delete result.atts

  result.rwys = jsonApproval.rwys.map((v, index) => {
    delete v?.key
    return { key: index + 1, ...v }
  })

  if (jsonApproval.amts) {
    for (const element of jsonApproval.amts) {
      // console.log(element)
      result[element.spec_key] = element.value
    }
  }
  console.log(result)
  return result
}

const deflatJson = (data, q, attachments) => {
  console.log('deflatJson data:', data)
  console.log('deflatJson q', q)

  const res = {
    'id': data.id,
    'act': data.act,
    'apt_dep': data.apt_dep,
    'apt_arr': data.apt_arr,
    'rwys': data.rwys,
    'info': data.info,
  }

  if (data.category_select) {
    res.cat = data.category_select
  } else {
    res.cat = null
  }

  const tempJsonApproval = { ...data }
  delete tempJsonApproval.id

  const tempAmts = []
  for (const element of q) {
    const t = { spec_key: element.name, value: tempJsonApproval[element.name] }
    tempAmts.push(t)
    delete tempJsonApproval[element.name]
  }

  console.log('tempAmts', tempAmts)

  tempJsonApproval.amts = tempAmts
  tempJsonApproval.atts = [...attachments]
  res.jsonApproval = { ...tempJsonApproval }

  return res
}

const FileHandler = ({
  apt, act, updateAttachment, formLock, selected,
}) => {
  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)
  const [fileTable, setFileTable] = useState([])
  const [opening, setOpening] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [descriptionInput, setDescriptionInput] = useState('')

  const updateTable = async () => {
    try {
      const res = await apiService.getAllAttachment(act, apt)
      console.log('getAllAttachment', res)
      const tempData = res.map((v) => ({ ...v, key: v.id }))

      setFileTable(tempData)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    setSelectedRowKeys([...selected])

    const i = async () => {
      try {
        await updateTable()
      } catch (error) {
        console.error(error)
      }
    }
    i()
  }, [selected])

  const handleUpload = async () => {
    console.log('fileList', fileList)
    setUploading(true)

    const t = {
      act: act,
      apt_dep: apt,
      fkey: fileList[0].name,
      description: descriptionInput,
      contentType: fileList[0].type,
    }
    try {
      const res = await apiService.addFile(act, apt, t)
      console.log('addFile', res)
      const putRes = await apiService.putFileS3(res.signedLink, fileList[0])
      console.log('putRes', putRes)
      setFileList([])
      setDescriptionInput('')
      await updateTable()
      notificationHandler('success', 'Done')
    } catch (error) {
      console.error(error)
      notificationHandler('error', 'Failed to upload')
    }

    setUploading(false)
  }

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      return false
    },
    fileList,
  }

  const getLink = async (id) => {
    setOpening(id)
    try {
      const res = await apiService.getAttachment(id)
      console.log('getAttachment', res)
      window.open(res.signedLink, '_blank')
      setOpening(false)
    } catch (error) {
      console.error(error)
      setOpening(false)
    }
  }

  const columns = [
    {
      title: 'createdBy',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button size='small' key={`${record.id}_open_button`} onClick={() => { getLink(record.id) }} loading={opening === record.id}>
            Open file
          </Button>
        </>
      ),
    },
  ]

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
    const filteredList = fileTable.filter((item) => (newSelectedRowKeys.includes(item.key)))
    console.log('filteredList', filteredList)
    updateAttachment(filteredList)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => (formLock ? { disabled: true } : { disabled: false }),
  }

  return (
    <>
      <Table
        rowkey="id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={fileTable}
        size="small"
        pagination={{
          pageSize: 5,
        }}
      />
      <div style={{ maxWidth: '450px' }}>
        <Upload
          {...props}
          accept="application/pdf"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />} disabled={fileList.length > 0}>Select File</Button>
        </Upload>
        <Input style={{ marginTop: 12, }} placeholder="Description" value={descriptionInput} onChange={(e) => { setDescriptionInput(e.target.value) }} />
        <Button
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{
            marginTop: 12,
          }}
        >
          {uploading ? 'Uploading' : 'Start Upload'}
        </Button>
      </div>
    </>
  )
}

const EditForm = ({ data, updateTable }) => {
  // console.log('data: ', JSON.stringify(data, null, 2))
  // console.log('jsonApproval: ', JSON.stringify(JSON.parse(data.jsonApproval), null, 2))
  const aptQ = useRecoilValue(APTQuestions)
  const apt = useRecoilValue(APTList)

  const [editForm] = Form.useForm()
  const [formLock, setFormLock] = useState(false)
  const [selectedAttachment, setSelectedAttachment] = useState([])
  const [runways, setRunways] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])

  const [changed, setChanged] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [open, setOpen] = useState(false)
  const [saveButton, setSaveButton] = useState(false)

  const initForm = () => {
    editForm.resetFields()
    const flatted = flatJson(data)
    setRunways(flatted.rwys ? flatted.rwys.map((v, index) => ({ key: index + 1, ...v })) : [])
    const selectedF = flatted.atts.map((item) => (item.id))
    setSelectedFiles(selectedF)
    editForm.setFieldsValue(flatted)
    setFormLock(false)
    setSaveButton(false)
    setChanged(false)
  }

  const updateAttachment = async (list) => {
    setSelectedAttachment([...list])
    console.log('selectedAttachment', [...list])
    setChanged(true)
  }

  const setRunwayArray = (v) => {
    console.log('runways', runways)
    console.log('v', v)
    let tempRwy = []
    if (runways.length > 0) {
      if (v.key) {
        tempRwy = [...runways].filter(item => item.key !== v.key)
      } else {
        tempRwy = [...runways]
      }
    }
    const tempV = { ...v }
    delete tempV.key
    const nokey = tempRwy.map(obj => {
      delete obj.key
      return obj
    })
    const tempArray = [...nokey, tempV].map((v, index) => ({ key: index + 1, ...v }))
    setChanged(true)
    setRunways(tempArray)
  }

  const deleteRunway = (rwyId) => {
    //console.log(runways)
    let tempRwy = []
    tempRwy = [...runways].filter(item => item.key !== rwyId)
    const nokey = tempRwy.map(obj => {
      delete obj.key
      return obj
    })
    const tempArray = nokey.map((v, index) => ({ key: index + 1, ...v }))
    setRunways(tempArray)
    setChanged(true)
  }

  const onFinish = async (values) => {
    try {
      setFormLock(true)
      console.log('values', values)
      /* const attachments = [...selectedAttachment].map((i, v) => ({
        id: i.id, description: i.description, position: v + 1, createdAt: i.createdAt, createdBy: i.createdBy,
      }))
      console.log(attachments)
      const resAttachment = await apiService.setAttachment(values.act, values.apt_dep, values.id, attachments)
      */
      values.rwys = [...runways]
      const deflatted = deflatJson(values, aptQ, selectedAttachment)
      console.log('deflatted', deflatted)

      const aptData = apt.filter((obj) => obj.icao === data.apt_dep)

      // console.log(aptData)

      deflatted.jsonApproval.apt_dep_iata = aptData[0].iata
      deflatted.jsonApproval.apt_dep_name = aptData[0].name
      const res = await apiService.updateApproval(deflatted)
      console.log('res', res)
      notificationHandler('success', 'Draft saved successfully')
      setIsModalVisible(false)
      updateTable()
    } catch (error) {
      console.error(error)
      notificationHandler('error', 'Failed to save draft')
    }
    // console.log('resAttachment', resAttachment)
  }


  const showModal = (i) => {
    initForm()

    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
    updateTable()
  }

  const handleCancel = () => {
  }

  const handleConfirmCancel = () => {
    setOpen(false)
  }

  const handleConfirmOk = () => {
    setOpen(false)
    setChanged(false)
    setIsModalVisible(false)
  }

  const handleClose = () => {
    if (changed) {
      setOpen(true)
    } else {
      setIsModalVisible(false)
    }
  }

  const handleSaveButton = () => {
    editForm.submit()
  }

  const onFormChange = () => {
    setChanged(true)
    //alert('form changed')
    console.log(changed)
  }

  const b = [...staticQ, ...aptQ]
  //<FileHandler apt={data.apt_dep} act={data.act} updateAttachment={updateAttachment} formLock={formLock} />
  console.log('specs keys', b)
  return (
    <>

      <Button type="primary" key={`${data.id}_edit_button`} onClick={showModal} size="small">
        Edit
      </Button>
      <Modal
        title={`Edit approval ID ${data.id}`}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        closable={false}
        destroyOnClose={true}
        width="80%"
        style={{ top: 25 }}
        footer={[
          <Button
            type='primary'
            key="Save"
            loading={saveButton}
            onClick={handleSaveButton}
          >
            Save
          </Button>,
          <Popconfirm
            key="Close"
            title="Warning"
            okText="Yes"
            cancelText="No"
            description="Values changed, do you want to close and discard changes?"
            open={open}
            onConfirm={handleConfirmOk}
            onCancel={handleConfirmCancel}
            placement="bottom"
          >
            <Button onClick={handleClose}>
              Close
            </Button>
          </Popconfirm>,
        ]}
      >
        <Form
          form={editForm}
          name="approval-edit-form"
          onFinish={onFinish}
          disabled={formLock}
          onValuesChange={onFormChange}
          size='small'
        >
          {b.map((z) => <FormGen {...z} key={`${z.name}_key`} />)}
        </Form>
        <Divider />
        <Title level={5}>Approval Attachments</Title>
        <p>(select the attachments to be linked to the approval)</p>
        <FileHandler apt={data.apt_dep} act={data.act} updateAttachment={updateAttachment} formLock={formLock} selected={selectedFiles} />
        <Divider />
        <Title level={5}>Runway Eligibility Analysis</Title>
        <RunwaysTable data={runways} setRunwayArray={setRunwayArray} deleteRunway={deleteRunway} apt={data.apt_dep} act={data.act} />
        <Divider />

      </Modal>
    </>
  )
}

const ListAll = ({ apt, act }) => {
  const [allAppr, setAllAppr] = useState([])
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState(false)

  const [openCollapse, setOpenCollapse] = useState([])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isActiveModalVisible, setIsActiveModalVisible] = useState(false)
  const [isNewDraftModalVisible, setIsNewDraftModalVisible] = useState(false)
  const [isCloneModalVisible, setIsCloneModalVisible] = useState(false)
  const [editApproval, setEditApproval] = useState({})
  const [activeApproval, setActiveApproval] = useState({})
  const [newDraftData, setNewDraftData] = useState({})
  const [activeOrRevoke, setActiveOrRevoke] = useState(false)

  const updateTable = async () => {
    try {
      setLoading(true)
      const res = await apiService.getAllApprovalVersion(apt, act)

      const filteredApprovalList = res.filter(item => item['apt_arr'] === null)

      //const n = res.map((t) => (t[0]))
      //console.log('getAllApprovalVersion', res)
      // eslint-disable-next-line no-nested-ternary

      const sortedList = filteredApprovalList.sort((n, m) => ((m.modifiedAt < n.modifiedAt) ? -1 : ((m.modifiedAt > n.modifiedAt) ? 1 : 0)))
      console.log(sortedList)
      const tempData = sortedList.map((v, index) => ({ ...v, key: index + 1 }))

      const selectedTemp = tempData.filter((g) => (g.version !== null && g.revokedAt === null))
      setAllAppr(tempData)
      setSelected(selectedTemp[0])
      if (tempData.length === 0) {
        setIsNewDraftModalVisible(true)
      }
      if (selectedTemp.length === 0) {
        setOpenCollapse([1])
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const i = async () => {
      try {
        await updateTable()
      } catch (error) {
        console.error(error)
      }
    }
    i()
  }, [])

  const viewApproval = (i) => {
    setSelected(false)
    console.log(i)
    setSelected(i)
  }

  const handleEditCancel = async () => {
    setEditApproval({})
    setIsModalVisible(false)
    await updateTable()
  }

  const showActiveModal = async (i, u) => {
    setActiveOrRevoke(u)
    setActiveApproval(i)
    setIsActiveModalVisible(true)
  }

  const handleActiveOk = async () => {
    setActiveApproval({})
    setIsActiveModalVisible(false)
    await updateTable()
  }

  const handleActiveCancel = async () => {
    setActiveApproval({})
    setIsActiveModalVisible(false)
    await updateTable()
  }

  const handleNewDraftOk = async () => {
    setIsNewDraftModalVisible(false)
    await updateTable()
  }

  const handleNewDraftCancel = async () => {
    setIsNewDraftModalVisible(false)
    //await updateTable()
  }

  const showClone = (i) => {
    setNewDraftData(i)
    setIsCloneModalVisible(true)
  }

  const handleCloneOk = async () => {
    setNewDraftData({})
    setIsCloneModalVisible(false)
    await updateTable()
  }

  const handleCloneCancel = async () => {
    setNewDraftData({})
    setIsCloneModalVisible(false)
    await updateTable()
  }

  const columns = [
    {
      title: 'Status',
      key: 'status',
      render: (text, record) => {
        if (isActive(record) === true) {
          return (<>Active</>)
        }
        if (record.approval_version !== null && record.revokedAt !== null) {
          return (<>Revoked</>)
        }
        return (
          <>Draft</>
        )
      },
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'modifiedAt',
      dataIndex: 'modifiedAt',
      key: 'modifiedAt',
      render: (text) => {
        return (<>{format(new Date(text), 'dd.MM.yyyy HH.mm.ss')}</>)
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        if (isActive(record) === true) {
          return (
            <>
              <Button style={{ marginRight: '5px' }} type="primary" key={`${record.id}_view_button`} onClick={() => { viewApproval(record) }} size="small">
                View
              </Button>
              <Button style={{ marginRight: '5px' }} type="primary" key={`${record.id}_newdraft_button`} onClick={() => { showClone(record) }} size="small">
                Copy as draft
              </Button>
              <Button style={{ marginRight: '5px' }} danger key={`${record.id}_revoke_button`} onClick={() => { showActiveModal(record, false) }} size="small">
                Revoke
              </Button>
            </>
          )
        }
        if (record.approval_version !== null && record.revokedAt !== null) {
          return (
            <>

              <Button style={{ marginRight: '5px' }} type="primary" key={`${record.id}_view_button`} onClick={() => { viewApproval(record) }} size="small">
                View
              </Button>

              <Button style={{ marginRight: '5px' }} type="primary" key={`${record.id}_newdraft_button`} onClick={() => { showClone(record) }} size="small">
                Copy as draft
              </Button>
            </>
          )
        }
        return (
          <>

            <Button style={{ marginRight: '5px' }} type="primary" key={`${record.id}_view_button`} onClick={() => { viewApproval(record) }} size="small">
              View
            </Button>

            <Button style={{ marginRight: '5px' }} type="primary" key={`${record.id}_newdraft_button`} onClick={() => { showClone(record) }} size="small">
              Copy as draft
            </Button>
            <Button style={{ marginRight: '5px' }} type="primary" key={`${record.id}_active_button`} onClick={() => { showActiveModal(record, true) }} size="small">
              Activate
            </Button>
            <EditForm data={record} updateTable={updateTable} />
          </>
        )
      },
    },
  ]

  const handleCollapse = () => {
    if (openCollapse.length === 0) {
      setOpenCollapse([1])
    } else {
      setOpenCollapse([])
    }
  }

  const rowClassName = (record, index) => {
    let classNames = ''
    if (selected) {
      if (record.id === selected.id) {
        classNames += 'selected-row '
      }
    }
    if (isActive(record)) {
      classNames += 'bold-row '
    }
    // Lisää tarvittaessa lisää ehtoja
    return classNames.trim()
    //return record.id === selected.id ? 'bold-row' : ''
  }

  return (
    <>
      <Title level={4}>Airport: {apt} - Aircraft: {act}</Title>
      <Divider />
      {
        loading ? <Empty />
          : (
            <>
              <Collapse ghost activeKey={openCollapse} onChange={() => handleCollapse()}>
                <Panel key="1" header="Version control">
                  <Table
                    rowkey="idACT"
                    columns={columns}
                    dataSource={allAppr}
                    size="small"
                    pagination={{ pageSize: 5 }}
                    rowClassName={rowClassName}
                  />
                </Panel>
              </Collapse>
              {selected ? <SelectedApproval data={selected} /> : <></>}
            </>
          )
      }
      <Modal
        title={`${activeOrRevoke ? 'Activate' : 'Revoke'}`}
        open={isActiveModalVisible}
        onOk={handleActiveOk}
        onCancel={handleActiveCancel}
        maskClosable={false}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleActiveCancel}>
            Close
          </Button>,
        ]}
      >
        {activeApproval.id ? <ApprovalForm data={activeApproval} active={activeOrRevoke} handleActiveOk={handleActiveOk} /> : <></>}
      </Modal>

      <Modal
        title="Copy as a draft"
        open={isCloneModalVisible}
        onOk={handleCloneOk}
        onCancel={handleCloneCancel}
        maskClosable={false}
        style={{ top: 25 }}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleCloneCancel}>
            Close
          </Button>,
        ]}
      >
        {newDraftData.id ? <Clone data={newDraftData} handleCloneOk={handleCloneOk} /> : <></>}
      </Modal>
      <Modal
        title="New"
        open={isNewDraftModalVisible}
        onOk={handleNewDraftOk}
        onCancel={handleNewDraftCancel}
        maskClosable={false}
        width="40%"
        style={{ top: 25 }}
        destroyOnClose={true}
        footer={[
        ]}
      >
        <>
          <p>Can not find earlier draft versions. Want to create a new one?</p>
          <NewDraft data={{ act: act, apt_dep: apt }} updateTable={updateTable} />
        </>
      </Modal>
    </>
  )
}

const SelectedApproval = ({ data }) => {
  const [approval, setApproval] = useState()
  const aptQ = useRecoilValue(APTQuestions)
  const rwyQ = useRecoilValue(RWYQuestions)
  const [selectedForm] = Form.useForm()
  const [formLock, setFormLock] = useState(true)
  const [opening, setOpening] = useState(false)

  const [rwyList, setRwyList] = useState([])


  useEffect(() => {
    selectedForm.resetFields()
    const flatted = flatJson(data)
    setApproval(flatted)

    console.log('rwyQ', rwyQ)
    console.log('rwys', flatted.rwys)
    const rwyQt = {}
    for (const iterator of rwyQ) {
      rwyQt[iterator.name] = {}
      for (const j of iterator.options) {
        rwyQt[iterator.name][j.value] = j.display
      }
    }
    console.log(rwyQt)
    const tRwys = flatted.rwys.map((item) => {
      console.log(item['rwy-analysis-method-select'])
      const k = rwyQt['rwy-cat-select'][item['rwy-cat-select']]
      const analysis = rwyQt['rwy-analysis-method-select'][item['rwy-analysis-method-select']]
      console.log(k)
      console.log(analysis)

      return { ...item, 'cat': k, 'analysis': analysis }
    })
    setRwyList(tRwys)
    selectedForm.setFieldsValue(flatted)
  }, [data])

  const inputs = [
  ]

  const b = [...inputs, ...aptQ]

  // console.log('b', b)
  if (!approval) {
    return (
      <></>
    )
  }

  const rwysColumns = [
    {
      title: 'Runway',
      dataIndex: 'rwy',
      key: 'rwy',
    },
    {
      title: 'Category',
      dataIndex: 'cat',
      key: 'cat',
    },
    {
      title: 'Analysis method',
      dataIndex: 'analysis',
      key: 'analysis'
    }
  ]

  const getLink = async (id) => {
    setOpening(id)
    try {
      const res = await apiService.getAttachment(id)
      console.log('getAttachment', res)
      window.open(res.signedLink, '_blank')
      setOpening(false)
    } catch (error) {
      console.error(error)
      setOpening(false)
    }
  }

  const attsColumns = [
    {
      title: 'File description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button size='small' key={`${record.id}_open_button`} onClick={() => { getLink(record.id) }} loading={opening === record.id}>
            Open file
          </Button>
        </>
      ),
    },
  ]

  const getTitle = (d) => {
    // console.log('getTitle', d)
    if (isActive(d)) {
      return `Active approval - Version ${d.version}`
    }
    if (d.revokedAt) {
      return 'Revoked approval - Not valid approval!'
    }

    // ? `Version ${approval.version}` : 'DRAFT version - Not valid approval!'
    return 'DRAFT version - Not valid approval!'
  }

  return (
    <>
      <div style={{ paddingLeft: '32px', paddingRight: '32px' }}>
        <Divider />
        <Descriptions bordered column={1} size="small" title={getTitle(approval)}>
          <Descriptions.Item label="ICAO code">{approval.apt_dep}</Descriptions.Item>
          <Descriptions.Item label="Aircraft type">{approval.act}</Descriptions.Item>
          <Descriptions.Item label="Approved Version">{approval.version ? approval.version : 'DRAFT'}</Descriptions.Item>
          <Descriptions.Item label="Approved At">{approval.approvedAt ? format(new Date(approval.approvedAt), 'dd.MM.yyyy') : ''}</Descriptions.Item>
          <Descriptions.Item label="Approved By">{approval.approvedBy}</Descriptions.Item>
          <Descriptions.Item label="Description/Info"><span style={{ whiteSpace: 'pre-line' }}>{approval.info ? approval.info : ''}</span></Descriptions.Item>
        </Descriptions>
        <Divider />
        <Form form={selectedForm} disabled={formLock} className='approval-view'>
          {b.map((z) => <FormGen {...z} key={`${z.name}_key`} />)}
        </Form>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div style={{ padding: '16px' }}>
              <Table title={() => <Title level={5}>Approval Attachments</Title>} rowkey="key" size="small" dataSource={approval.atts} columns={attsColumns} />
            </div>
          </Col>
          <Col span={12}>
            <div style={{ padding: '16px' }}>
              <Table title={() => <Title level={5}>Runway Eligibility Analysis</Title>} rowkey="key" size="small" dataSource={rwyList} columns={rwysColumns} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

const AirportApproval = () => {
  const { aptId, actId } = useParams()

  return (
    <>
      <ListAll apt={aptId} act={actId} />
    </>
  )
}

export default AirportApproval
