import InputOma from './Input'
import Checkbox from './Checkbox'
import SelectOma from './Select'
import ChecklistGroup from './ChecklistGroup'
import TextOma from './Text'
import NoteCheck from './NoteCheck'

const Forms = (props) => {
  const { type } = props
  switch (type) {
  case 'input':
    return (<InputOma {...props} />)
  case 'checkbox':
    return (<Checkbox {...props} />)
  case 'select':
    return (<SelectOma {...props} />)
  case 'checklist-group':
    return (<ChecklistGroup {...props} />)
  case 'note-check':
    return (<NoteCheck {...props} />)
  case 'text':
    return (<TextOma {...props} />)
  default:
    return (<></>)
  }
}

export default Forms
