/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react'
import {
  Checkbox, Form, Divider, Row, Col, Typography, Radio,
} from 'antd'

import '../../App.less'

const { Title } = Typography

const style = {
  padding: '2px 0',
  textAling: 'center',
}

const ChecklistGroup = ({
  name, headers, value, items,
}) => (
  <>
    <Divider />
    <Row gutter={16}>
      {headers.map((z) => (
        <Col span={6} key={`${z.name}_headers_key`} style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}>
          <div className="centered" style={style}>
            <Title level={5}>{z.name}</Title>
          </div>
        </Col>
      ))}
    </Row>
    {items.map((z) => (
      <div key={`${z.name}_group_key`}>
        <Form.Item name={z.name} style={{ marginBottom: '0px' }}>
          <Checkbox.Group
            style={{ width: '100%' }}
          >
            <Row gutter={16}>
              <Col span={6} className="gutter-row" style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                <div style={style} className="centered" key={`${z.name}_label_key`}>
                  <Title level={5}>{z.label}</Title>
                </div>
              </Col>
              {z.options.map((x) => (
                <Col span={6} className="gutter-row" style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }} key={`${z.name}_${x.value}_key`}>
                  <div style={style}>
                    <Checkbox style={style} value={x.value}>{x.display}</Checkbox>
                  </div>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </div>
    ))}
    <Divider />
  </>

)

export default ChecklistGroup
