import { useEffect, useState } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
  Link,
} from 'react-router-dom'
import { format } from 'date-fns'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Amplify, Auth } from 'aws-amplify'
import {
  Table, Input, Modal, Button, Select, Divider
} from 'antd'

import Login from './components/Login'
import ProtectedRoute from './components/ProtectedRoute'
import Nav from './components/Nav'

import Airports from './components/Airports'
import Aircrafts from './components/Aircrafts'
import ApprovalRoutes from './components/Routes'
import AirportApproval from './components/AirportApproval'
import AirportApprovalsByFleet from './components/AirportApprovalsByFleet'
import RouteApproval from './components/RouteApproval'

import apiService from './services/apiService'

import navState from './atoms/NavState'
import { ACTList, APTList } from './atoms/initAtom'

import './App.css'

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-west-1_ayyFNVnMY',
    // userPoolId: 'eu-north-1_q34AECDJz',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '6sgc8csddo3vvsf6b5qou82jp7',
    // userPoolWebClientId: '5npj468da00c0ngipnqjc1r8qk',

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: 'cognito.flynorra.com',
      // domain: 'n7-test-pool.auth.eu-north-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      // scope: ['openid', 'email'],
      redirectSignIn: process.env.REACT_APP_SIGNIN_URL,
      redirectSignOut: process.env.REACT_APP_SIGNOUT_URL,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
})


// eslint-disable-next-line no-unused-vars
const ActiveApprovalsTable = () => {
  const [sourceData, setSourceData] = useState([])
  const [data, setData] = useState([])
  const act = useRecoilValue(ACTList)
  const apt = useRecoilValue(APTList)
  const [value, setValue] = useState('')

  const [isModalOpen, setIsModalOpen] = useState(false)

  // console.log('ACTlist', act)
  // console.log('APTlist', apt)

  useEffect(() => {
    const i = async () => {
      try {
        const res = await apiService.getActiveApprovals()
        // console.log('getActiveApprovals', res)
        // setData(res)

        const tempAPT = {}

        // eslint-disable-next-line array-callback-return
        apt.map((a, index) => {
          tempAPT[a.icao] = { key: index + 1, ...a }
        })
        // console.log('tempAPT', tempAPT)
        // eslint-disable-next-line array-callback-return
        res.map((b) => {
          // console.log('b', b, tempAPT[b.apt_dep])

          tempAPT[b.apt_dep][b.act] = { ...b }
        })

        const u = (Object.keys(tempAPT).map((h) => (tempAPT[h])))
        // console.log(u)
        setSourceData(u)
        setData(u)
      } catch (error) {
        console.error(error)
      }
    }

    i()
  }, [])

  // //////

  // /////

  const columns = [
    {
      title: 'Airport',
      dataIndex: 'icao',
      key: 'airport',
      render: (text) => <p>{text}</p>,
    },
  ]

  for (const a of act) {
    const c = {
      title: `${a.FD} - ${a.FB}`,
      dataIndex: a.FB,
      key: a.FB,
      onCell: (record) => {
        if (record[a.FB]) {
          return {
            style: {
              // eslint-disable-next-line no-nested-ternary
              background: record[a.FB].category_select === 'C' ? '#ffa39e' : record[a.FB].category_select === 'B' ? '#ffe7ba' : null,
            },
          }
        }
        return {
        }
      },
      render: (d) => {
        if (d) {
          return (
            <>
              <p>{d.category_select ? <b>{d.category_select}</b> : ''}</p>
              <span style={{ whiteSpace: 'pre-line' }}>{d.description ? d.description : ''}</span>
              <p>
                Rev:
                {' '}
                {d.approval_version ? <b>{d.approval_version}</b> : ''}
                {' - '}
                Approved:
                {' '}
                {d.approvedAt ? format(new Date(d.approvedAt), 'dd.MM.yyyy') : ''}
              </p>
              <Link to={`/approvals/${d.apt_dep}/${d.act}`}>View/Edit</Link>
            </>
          )
        }
        return (<></>)
      },
    }
    columns.push(c)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Input
        placeholder="Airport filter"
        style={{ width: 200 }}
        value={value}
        onChange={(e) => {
          const currValue = e.target.value
          setValue(currValue.toUpperCase())
          const filteredData = sourceData.filter((entry) => entry.icao.includes(currValue.toUpperCase()))
          // console.log(filteredData)
          setData(filteredData)
        }}
      />
      <Button type="primary" onClick={showModal}>
        New/View draft
      </Button>
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        bordered
        pagination={{ pageSize: 15 }}
      />
      <Modal
        title="New draft"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        maskClosable={false}
        width="35%"
        style={{ top: 25 }}
        footer={[
          <Button key="back" onClick={handleOk}>
            Close
          </Button>,
        ]}
      >
      </Modal>
    </>
  )
}

const Main = () => {
  const setNavStateStatus = useSetRecoilState(navState)
  const act = useRecoilValue(ACTList)
  const [selectAircraft, setSelectAircraft] = useState([])
  const [selectedAct, setSelectedAct] = useState()



  const handleChange = (value) => {
    console.log(`selected ${value}`)
    setSelectedAct(`${value}`)
    localStorage.setItem('selectedACType', value)
    // const h = apt.filter((f) = {})
  }

  useEffect(() => {
    setNavStateStatus({ menu: 1, submenu: '' })
    const actype = localStorage.getItem('selectedACType') || ['AT75']
    setSelectedAct(actype)
    const i = async () => {
      try {
        const v = act.map((u) => ({ value: u.icao, label: `${u.icao} - ${u.name}` }))
        console.log(act, v)
        setSelectAircraft(v)
      } catch (error) {
        console.error(error)
      }
    }
    i()
  }, [act])

  return (
    <div>
      <Select
        style={{ width: 240 }}
        onChange={handleChange}
        options={selectAircraft}
        value={selectedAct}
      />
      <Divider/>
      {selectedAct ? <AirportApprovalsByFleet act={selectedAct} /> : <></>}
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <ProtectedRoute auth={Auth} />,
    children: [
      {
        path: '/',
        element: <Nav auth={Auth} />,
        children: [
          {
            path: '/',
            element: <Main />,
          },
          {
            path: '/routes',
            element: <ApprovalRoutes />,
          },
          {
            path: '/airports',
            element: <Airports />,
          },
          {
            path: '/aircrafts',
            element: <Aircrafts />,
          },
          {
            path: '/approvals',
            element: <AirportApprovalsByFleet />,
          },
          {
            path: '/approvals/:aptId/:actId',
            element: <AirportApproval />,
          },
          {
            path: '/routes/:actId/:aptId/:arrId',
            element: <RouteApproval />,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <Login auth={Auth} />,
  },
])

const App = () => (
  <RouterProvider router={router} />
)

export default App
