/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react'
import {
  Checkbox, Form, Divider, Row, Col, Typography, Radio, Input
} from 'antd'

import '../../App.less'

const { Title } = Typography
const { TextArea } = Input

const style = {
  padding: '2px 0',
  textAling: 'center',
}

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 12,
  },
}

const ChecklistGroup = ({
  name, headers, value, items, options, disabled, label
}) => (
  <>
    <Divider />
    <Row gutter={20}>
      <Col span={8} className="gutter-row">
        <p>{label}</p>
      </Col>
      <Col span={8} className="gutter-row">
        <Form.Item
          name={`${name}-note`}
        >
          <TextArea placeholder="" autoSize={{ minRows: 2 }} disabled={disabled} />
        </Form.Item>
      </Col>
      <Col span={4} className="gutter-row" >
        <Form.Item
          label="Checked"
          name={`${name}-check`}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Col>
    </Row>
  </>

)

export default ChecklistGroup
