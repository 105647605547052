import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'auth', // this key is using to store data in local storage
  storage: localStorage, // configurate which stroage will be used to store the data
})

const authState = atom({
  key: 'authState', // unique ID (with respect to other atoms/selectors)
  default: {
    auth: false,
    signInUserSession: {},
  }, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
})

const tokenAuthSelector = selector({
  key: 'tokenAuth',
  get: ({ get }) => {
    const a = get(authState)
    console.log(a.signInUserSession.idToken.jwtToken)
    return (a.signInUserSession.idToken.jwtToken)
  },
})

export { authState, tokenAuthSelector }
