/* eslint-disable max-len */
import { Checkbox, Form } from 'antd'

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 12,
  },
}

const CheckboxOma = ({
  name, label,
}) => {
  console.log()
  return (
    <Form.Item
      {...formItemLayout}
      label={label}
      name={name}
      valuePropName="checked"
    >
      <Checkbox />
    </Form.Item>
  )
}

export default CheckboxOma
