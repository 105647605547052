/* eslint-disable default-case */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import {
  Table, Button, Modal, Form, Input, Popconfirm
} from 'antd'
// import { SearchOutlined } from '@ant-design/icons'

import navState from '../atoms/NavState'
import apiService from '../services/apiService'

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
}

const EditForm = ({ data, updateTable }) => {
  // console.log('data', data)
  const [form] = Form.useForm()
  const [formLock, setFormLock] = useState(false)
  const [changed, setChanged] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [editAirport, setEditAirport] = useState({})

  const [open, setOpen] = useState(false)

  const [saveButton, setSaveButton] = useState(false)

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue({ ...data })
    setFormLock(false)
    setSaveButton(false)
  }, [])

  const onFormChange = () => {
    setChanged(true)
    //alert('form changed')
    console.log(changed)
  }

  const onFinish = async (values) => {
    setSaveButton(true)
    setFormLock(true)
    console.log(values)
    const res = await apiService.updateAirport(values)
    console.log('res', res)
    updateTable()
    setEditAirport({})
    setIsModalVisible(false)
  }

  const showModal = (i) => {
    setEditAirport(i)
    form.setFieldsValue({ ...data })
    setFormLock(false)
    setSaveButton(false)
    setChanged(false)
    setIsModalVisible(true)
  }

  const handleOk = () => {
    updateTable()
    setEditAirport({})
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    updateTable()
    setEditAirport({})
  }

  const handleConfirmCancel = () => {
    setOpen(false)
  }

  const handleConfirmOk = () => {
    setOpen(false)
    setIsModalVisible(false)
    setChanged(false)
  }

  const handleClose = () => {
    if (changed) {
      setOpen(true)
    } else {
      setIsModalVisible(false)
    }
  }

  const handleSaveButton = () => {
    form.submit()
  }

  return (
    <>
      <Button type="primary" key={`${data.icao}_edit_button`} onClick={() => { showModal(data) }}>
        Edit
        {' '}
        {data.icao}
      </Button>
      <Modal
        title={`Edit airport ${editAirport.icao}`}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button
            type='primary'
            key="Save"
            loading={saveButton}
            onClick={handleSaveButton}
          >
          Save
          </Button>,
          <Popconfirm
            key="Close"
            title="Warning"
            okText="Yes"
            cancelText="No"
            description="Values changed, do you want to close and discard changes?"
            open={open}
            onConfirm={handleConfirmOk}
            onCancel={handleConfirmCancel}
            placement="bottom"
          >
            <Button onClick={handleClose}>
              Close
            </Button>
          </Popconfirm>,
        ]}
      >
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          onValuesChange={onFormChange}
        >
          <Form.Item
            name="icao"
            label="ICAO code"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="name"
            label="Airport name"
          >
            <Input disabled={formLock} />
          </Form.Item>
          <Form.Item
            name="iata"
            label="IATA code"
            rules={[{ required: true, min: 3, max: 3 }]}
          >
            <Input disabled={formLock} />
          </Form.Item>
        </Form>
      </Modal>
    </>


  )
}

const NewForm = ({ airports }) => {
  // console.log('data', data)
  const [form] = Form.useForm()
  const [formLock, setFormLock] = useState(false)

  useEffect(() => {
    form.resetFields()
    setFormLock(false)
  }, [])

  const isIdAlreadyExists = (rule, value) => {
    if (airports.some(item => item.icao === value)) {
      return Promise.reject('ICAO already exists')
    }
    return Promise.resolve()
  }

  const onFinish = async (values) => {
    setFormLock(true)
    console.log(values)
    try {
      const res = await apiService.addAirport(values)
      console.log('res', res)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
      <Form.Item
        name="icao"
        label="ICAO code"
        rules={[
          {
            required: true,
            message: 'Please input ICAO',
          },
          {
            validator: isIdAlreadyExists,
          },
        ]}
      >
        <Input disabled={formLock} />
      </Form.Item>
      <Form.Item
        name="name"
        label="Name"
      >
        <Input disabled={formLock} />
      </Form.Item>
      <Form.Item
        name="iata"
        label="IATA code"
      >
        <Input disabled={formLock} />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

const Airports = () => {
  const [sourceData, setSourceData] = useState([])
  const [airports, setAirports] = useState([])
  const setNavStateStatus = useSetRecoilState(navState)


  const [newAircraftVisible, setNewAircraftVisible] = useState(false)
  const [filterForm] = Form.useForm()
  const [value, setValue] = useState('')

  const updateTable = async () => {
    try {
      filterForm.resetFields()
      const res = await apiService.getAllAirport()
      console.log('All airports:', res)
      const j = res.map((v, index) => ({ key: index + 1, ...v }))
      setAirports(j)
      setSourceData(j)
      // console.log(airports)
    } catch (error) {
      console.error(error)
    }

  }

  useEffect(() => {
    setNavStateStatus({ menu: 3, submenu: 'sub1' })
    updateTable()
  }, [])


  const showNewAirportModal = () => {
    setNewAircraftVisible(true)
  }

  const handleOkNewAirport = () => {
    updateTable()
    setNewAircraftVisible(false)
  }

  const handleCancelNewAirport = () => {
    updateTable()
    setNewAircraftVisible(false)
  }


  const filterTable = (t, catFilter) => {
    console.log(t, catFilter)
    const filteredData = sourceData.filter(
      (entry) =>
        (entry.icao.toLowerCase().includes(t.toLowerCase()) ||
        entry.iata.toLowerCase().includes(t.toLowerCase()))
    )
    console.log('filteredData', filteredData)
    setAirports(filteredData)
  }

  const filterHandler = (e, type) => {
    let airportF = `${value}`
    if (type === 'airport') {
      const currValue = e.target.value
      airportF = e.target.value
      setValue(currValue)
      console.log(value)
    }
    filterTable(airportF)
  }


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ICAO code',
      dataIndex: 'icao',
      key: 'icao',
    },
    {
      title: 'IATA code',
      dataIndex: 'iata',
      key: 'iata',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <EditForm data={record} updateTable={updateTable} />
      ),
    },
  ]

  return (
    <>
      <Form
        form={filterForm}
        layout="inline"
        style={{ marginBottom: '10px' }}
      >
        <Form.Item
          label="Airport search"
        >
          <Input
            placeholder='ICAO / IATA'
            style={{ width: 150 }}
            value={value}
            onChange={(e) => { filterHandler(e, 'airport') }}
          />
        </Form.Item>
        <Form.Item
          style={{ marginLeft: 'auto' }}
        >
          <Button key="new_button" type="primary" onClick={showNewAirportModal}>
            New Airport
          </Button>
        </Form.Item>
      </Form>

      <Table rowkey="idAPT" columns={columns} dataSource={airports} />
      <Modal
        title="New Airport"
        open={newAircraftVisible}
        onOk={handleOkNewAirport}
        onCancel={handleCancelNewAirport}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleCancelNewAirport}>
            Close
          </Button>,
        ]}
      >
        <NewForm airports={airports} />
      </Modal>
    </>
  )
}

export default Airports
