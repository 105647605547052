/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import {
  Form, Button, Spin
} from 'antd'

import { authState } from '../atoms/AuthState'

const Login = ({ auth: Auth }) => {
  const [loadingStatus, setLoadingStatus] = useState(true)
  const { state } = useLocation()
  const navigate = useNavigate()
  const loginRedirect = localStorage.getItem('login_redirect')
  const redirect = loginRedirect || '/'

  const setAuthState = useSetRecoilState(authState)

  useEffect(() => {
    const i = async () => {
      try {
        const t = await Auth.currentAuthenticatedUser()
        //console.log(t)

        setAuthState({
          auth: true,
          signInUserSession: { ...t.signInUserSession },
        })
        localStorage.removeItem('login_redirect')
        setTimeout(() => navigate(redirect), 1000)
      } catch (error) {
        setAuthState({
          auth: false,
          signInUserSession: { },
        })
        console.log('Not signed in')
        localStorage.removeItem('isAuthenticated')
        console.error(error)
        setLoadingStatus(false)
      }
    }
    if (state) {
      const search = state.from.search || ''
      localStorage.setItem('login_redirect', `${state.from.pathname}${search}`)
    }
    i()
  }, [])

  if (loadingStatus) {
    return (
      <div className="site-layout-background" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin tip="Loading..." size="large">
        </Spin>
      </div>)
  }

  return (
    // <div className="login-page" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: '#f0f0f0' }}>
    <div className="App">
      <header className="App-header">
        <div className="login-container">
          <Form>
            <Form.Item label="Login with your N7-account">
              <Button onClick={() => Auth.federatedSignIn({ provider: 'adfs.adn7.fi' })}>Login</Button>
            </Form.Item>
          </Form>
        </div>
      </header>
    </div>
  )
}

export default Login
