/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import {
  Table, Input, Modal, Button, Typography, Space,
  Form, Select, Checkbox, Descriptions
} from 'antd'

const { Text } = Typography

// import FormGen from './components/forms/FormGen'
import NewApproval from './NewApproval'

import apiService from '../services/apiService'

import { flatJson } from '../services/approvalJson'

// import navState from './atoms/NavState'
// import formState from './atoms/TestForm'
import { ACTList, APTList } from '../atoms/initAtom'


const AdditionalInfo = ({ data }) => {
  const approval = flatJson(data)
  return (
    <Descriptions bordered column={1} size="small">
      <Descriptions.Item label="Info"><span style={{ whiteSpace: 'pre-line' }}>{approval?.info ? approval.info : ''}</span></Descriptions.Item>
      <Descriptions.Item label="Restrictions/Threats"><span style={{ whiteSpace: 'pre-line' }}>{approval?.['restrictions-threats-info'] ? approval['restrictions-threats-info']  : ''}</span></Descriptions.Item>

    </Descriptions>
  )
}

const New = ({ approved, act }) => {
  const apt = useRecoilValue(APTList)
  const navigate = useNavigate()


  const [selectAirport, setSelectAirport] = useState([])
  const [selectAircraft, setSelectAircraft] = useState([])

  const [form] = Form.useForm()
  const [formLock, setFormLock] = useState(false)

  useEffect(() => {
    const i = async () => {
      try {
        const y = apt.map((u) => ({ value: u.icao, label: `${u.icao} - ${u.name}` }))
        console.log('approved', approved)

        const aptToRemove = approved.map(obj => obj.apt_dep)

        const selectList = y.filter(obj => !aptToRemove.includes(obj.value))

        setSelectAirport(selectList)
        console.log('y', selectList)

      } catch (error) {
        console.error(error)
      }
      //form.setFieldsValue({  })
    }
    i()
  }, [apt, approved])

  const handleChange = (value) => {
    console.log(`selected ${value}`)
    // const h = apt.filter((f) = {})
  }

  const onFinish = async (values) => {
    setFormLock(true)
    // console.log(values)
    navigate(`/approvals/${values.apt_dep}/${act}`)
  }

  return (
    <>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item
          name="apt_dep"
          label="Airport ICAO"
          rules={[{ required: true }]}

        >
          <Select
            onChange={handleChange}
            options={selectAirport}
            showSearch={true}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Open
          </Button>
        </Form.Item>
      </Form>
    </>
  )

}

const ActiveApprovalsTable = ({ act }) => {
  const [sourceData, setSourceData] = useState([])
  const [data, setData] = useState([])
  const [checkedCat, setCheckedCat] = useState(['A', 'B', 'C'])
  // const act = useRecoilValue(ACTList)
  // const apt = useRecoilValue(APTList)
  const [value, setValue] = useState('')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filterForm] = Form.useForm()

  // console.log('ACTlist', act)
  // console.log('APTlist', apt)

  useEffect(() => {
    const i = async () => {
      try {
        filterForm.resetFields()
        setCheckedCat(['A', 'B', 'C'])
        setValue('')
        const res = await apiService.getActiveApprovals(act)
        console.log('getActiveApprovals', res)
        const routeList = res.filter(item => item['apt_arr'] !== null)
        //const n = res.map((t) => (t[0]))
        //console.log(n)
        const k = routeList.map((v, index) => ({ key: index + 1, ...v }))
        console.log(k)
        setData(k)
        setSourceData(k)
      } catch (error) {
        console.error(error)
      }
    }

    i()
  }, [act])

  // //////

  // /////

  const columns = [
    {
      title: 'Dep (ICAO / IATA)',
      dataIndex: 'apt_dep',
      key: 'apt_dep',
      render: (text,d) => <Space direction="vertical">
        <Text>{d.jsonApproval.apt_dep_name}</Text>
        <Text strong>{text} / {d.jsonApproval.apt_dep_iata}</Text>
      </Space>,
    },
    {
      title: 'Arr (ICAO / IATA)',
      dataIndex: 'apt_arr',
      key: 'apt_arr',
      render: (text,d) => <Space direction="vertical">
        <Text>{d.jsonApproval.apt_dep_name}</Text>
        <Text strong>{text} / {d.jsonApproval.apt_dep_iata}</Text>
      </Space>,
    },
    {
      title: 'Approved at',
      dataIndex: 'approvedAt',
      render: (text,d) => <Space direction="vertical">
        <Text>Version: {d.version}</Text>
        <Text>{format(new Date(text), 'dd.MM.yyyy')}</Text>
      </Space>,
    },
    {
      title: 'Category',
      dataIndex: 'cat',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Actions',
      dataIndex: 'cat',
      render: (text, d) =>  <Link to={`/routes/${d.act}/${d.apt_dep}/${d.apt_arr}`}>View/Edit</Link>,
    }

  ]

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }


  const filterTable = (t,  catFilter) => {
    console.log(t, catFilter)
    const filteredData = sourceData.filter(
      (entry) =>
        (entry.apt_dep.toLowerCase().includes(t.toLowerCase()) ||
        entry.jsonApproval.apt_dep_iata.toLowerCase().includes(t.toLowerCase())) &&
        (catFilter.includes(entry.cat))
    )
    console.log('filteredData', filteredData)
    setData(filteredData)
  }

  const filterHandler = (e, type) => {
    let airportF = `${value}`
    let catF = [...checkedCat]
    if (type === 'airport') {
      const currValue = e.target.value
      airportF = e.target.value
      setValue(currValue)
      console.log(value)
    }
    if (type === 'cat') {
      console.log(e)
      catF = e
      setCheckedCat([...catF])
    }


    filterTable(airportF, catF)
  }

  const onChange = (checkedValues) => {
    console.log('checked = ', checkedValues)
  }
  const plainOptions = ['A', 'B', 'C']
  const options = [
    {
      label: 'A',
      value: 'A',
    },
    {
      label: 'B',
      value: 'B',
    },
    {
      label: 'C',
      value: 'C',
    },
  ]

  return (
    <>
      <Form
        form={filterForm}
        layout="inline"
        style={{ marginBottom: '10px' }}
      >
        <Form.Item
          label="Airport search"
        >
          <Input
            placeholder='ICAO / IATA'
            style={{ width: 150 }}
            value={value}
            onChange={(e) => {filterHandler(e, 'airport')}}
          />
        </Form.Item>
        <Form.Item
          label="Category filter"
        >
          <Checkbox.Group options={options} value={checkedCat} onChange={(e) => {filterHandler(e, 'cat')}} style={{ marginLeft: '10px' }}/>
        </Form.Item>
        <Form.Item
          style={{ marginLeft: 'auto' }}
        >
          <Button type="primary" onClick={showModal} >
            New/View draft
          </Button>
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        dataSource={data}
        rowKey={record => record.id}
        size="small"
        bordered
        pagination={{ pageSize: 15 }}
        rowClassName={ (record, index) => record.cat === 'C' ? 'category-c-row' : record.cat === 'B' ? 'category-b-row' : null }
        expandable={{
          expandedRowRender: (record) => (
            <AdditionalInfo data={record}/>
          ),
          rowExpandable: (record) => record.description !== 'Not Expandable',
        }}
      />
      <Modal
        title="Open unapproved"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        maskClosable={false}
        width="35%"
        style={{ top: 25 }}
        footer={[
          <Button key="back" onClick={handleOk}>
            Close
          </Button>,
        ]}
      >
        <><New approved={sourceData} act={act} /></>
      </Modal>
    </>
  )
}

const RoutesApprovalsByFleet = () => (
  <ActiveApprovalsTable act={'E190'}/>
)

export default RoutesApprovalsByFleet
