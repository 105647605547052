/* eslint-disable max-len */
import { Select, Form } from 'antd'

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 12,
  },
}

const SelectOma = ({
  name, label, options,
}) => (
  <Form.Item
    {...formItemLayout}
    label={label}
    name={name}
  >
    <Select>
      {options.map((z) => <Select.Option key={`${z.value}_key`} value={z.value}>{z.display}</Select.Option>)}
    </Select>
  </Form.Item>
)

export default SelectOma
