/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import {
  useParams,
} from 'react-router-dom'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { format } from 'date-fns'

import {
  Table, Descriptions, Form, Button, Modal,
  DatePicker, notification, message, Upload,
  Divider, Empty, Radio, Row, Col, Collapse,
  Typography, Input, Popconfirm
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import apiService from '../services/apiService'

import FormGen from './forms/FormGen'
// import NewApproval from './NewApproval'
import ApprovalForm from './ApprovalForm'

import {
  initState, ACTList, APTList, RWYQuestions,
} from '../atoms/initAtom'

import { staticQ } from './staticQuestions'
import notificationHandler from './notificationHandler'

const { Panel } = Collapse
const { Title } = Typography

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
}

const FileHandler = ({
  apt, act, rwy, updateAttachment, formLock, attachment, setAttachmentsTable, deleteAttachments
}) => {
  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)
  const [fileTable, setFileTable] = useState([])
  const [opening, setOpening] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [descriptionInput, setDescriptionInput] = useState('')

  const handleUpload = async () => {
    console.log('fileList', fileList)
    setUploading(true)

    const t = {
      act: act,
      apt_dep: apt,
      fkey: fileList[0].name,
      description: descriptionInput,
      contentType: fileList[0].type,
      rwy: rwy
    }
    try {
      const res = await apiService.addFile(act, apt, t)
      console.log('addFile', res)
      const putRes = await apiService.putFileS3(res.signedLink, fileList[0])
      console.log('putRes', putRes)
      setAttachmentsTable({ description: descriptionInput, id: res.result[0].insertId,  })
      setFileList([])
      setDescriptionInput('')
      notificationHandler('success', 'Done')
    } catch (error) {
      console.error(error)
      notificationHandler('error', 'Error')
    }

    setUploading(false)
  }

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      return false
    },
    fileList,
  }

  const getLink = async (id) => {
    setOpening(id)
    try {
      const res = await apiService.getAttachment(id)
      window.open(res.signedLink, '_blank')
      setOpening(false)
    } catch (error) {
      console.error(error)
      setOpening(false)
    }
  }

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button type="primary" key={`${record.id}_open_button`} size="small" onClick={() => { getLink(record.id) }} loading={opening === record.id}>
            Open file
          </Button>
          <Popconfirm
            title="Delete the attachment"
            description="Are you sure to delete this attachment?"
            onConfirm={() => {deleteAttachments(record.id)}}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger>Delete</Button>
          </Popconfirm>
        </>
      ),
    },
  ]

  return (
    <>
      <Table
        rowkey="key"
        columns={columns}
        dataSource={attachment}
        size="small"
        rowKey={'id'}
        pagination={{
          pageSize: 10,
        }}
      />
      <div style={{ maxWidth: '450px' }}>
        <Upload
          {...props}
          maxCount={1}
        >
          <Button icon={<UploadOutlined />} disabled={fileList.length > 0}>Select File</Button>
        </Upload>
        <Input  style={{ marginTop: 12, }}placeholder="Description" value={descriptionInput} onChange={(e) => {setDescriptionInput(e.target.value)}} />
        <Button
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{
            marginTop: 12,
          }}
        >
          {uploading ? 'Uploading' : 'Start Upload'}
        </Button>
      </div>
    </>
  )
}

const NewForm = ({ data, setRunwayArray, apt, act, handleNewOk }) => {
  const rwyQ = useRecoilValue(RWYQuestions)

  const [newRwyForm] = Form.useForm()

  const [formLock, setFormLock] = useState(true)

  const [analysisMethod, setAnalysisMethod] = useState(false)
  const [rwy, setRwy] = useState('')
  const [attachment, setAttachment] = useState([])

  useEffect(() => {
    newRwyForm.resetFields()
    setAnalysisMethod(false)
    setFormLock(false)

    return () => {
      newRwyForm.resetFields()
      setFormLock(false)
      setAnalysisMethod(false)
      setRwy('')
    }
  }, [data])

  const onFinish = async (values) => {
    setFormLock(true)
    console.log('values', values)

    setRunwayArray({ ...values, atts: [...attachment] })
    newRwyForm.resetFields()
    setAnalysisMethod(false)
    setAttachment([])
    setRwy('')
    setFormLock(false)
    handleNewOk()
  }

  const onChangeForm = () => {
    const o = newRwyForm.getFieldsValue()
    setRwy(o.rwy)
    console.log(o)
    if (o['rwy-analysis-method-select'] === 'data' || o['rwy-analysis-method-select'] === 'desktop' || o['rwy-analysis-method-select'] === 'operational') {
      setAnalysisMethod(true)
    } else {
      setAnalysisMethod(false)
    }
  }

  const input = [
    {
      name: 'rwy',
      label: 'Runway code',
      placeholder: '',
      type: 'input',
    },
  ]

  const setAttachmentsTable = (i) => {
    setAttachment([...attachment, i])
  }

  const deleteAttachments = (attId) => {
    let tempAtt = []
    tempAtt = [...attachment].filter(item => item.id !== attId)
    setAttachment(tempAtt)
  }

  const inputs = [...input, ...rwyQ]

  return (
    <>
      <Form form={newRwyForm} name="runway-new-form" onFinish={onFinish} onFieldsChange={onChangeForm} disabled={formLock}>
        {inputs.map((z) => <FormGen {...z} key={`${z.name}_key`} />)}
        <Divider />
        {analysisMethod ? <>
          <p>Analysis attachments</p>
          <FileHandler apt={apt} act={act} attachment={attachment} setAttachmentsTable={setAttachmentsTable} deleteAttachments={deleteAttachments} rwy={rwy} />
        </> : <></>}
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

const EditForm = ({ data, setRunwayArray, apt, act, handleEditOk }) => {
  const rwyQ = useRecoilValue(RWYQuestions)

  const [rwyForm] = Form.useForm()

  const [formLock, setFormLock] = useState(true)

  const [analysisMethod, setAnalysisMethod] = useState(false)
  const [rwy, setRwy] = useState('')
  const [attachment, setAttachment] = useState([])

  useEffect(() => {
    rwyForm.resetFields()
    console.log('runway edit data', data)
    rwyForm.setFieldsValue(data)
    setRwy(data.rwy)

    if (data['rwy-analysis-method-select'] === 'data' || data['rwy-analysis-method-select'] === 'desktop' || data['rwy-analysis-method-select'] === 'operational') {
      setAnalysisMethod(true)
    } else {
      setAnalysisMethod(false)
    }
    setAttachment([...data.atts])
    setFormLock(false)

    return () => {
      rwyForm.resetFields()
      setFormLock(false)
      setAnalysisMethod(false)
      setAttachment([])
      setRwy('')
    }
  }, [data])

  const onFinish = async (values) => {
    setFormLock(true)
    console.log('values', values)
    console.log('rwyQ', rwyQ)

    const tempRwyData = { key: data.key, ...values }

    if (values['rwy-analysis-method-select'] === 'data' || values['rwy-analysis-method-select'] === 'desktop' || values['rwy-analysis-method-select'] === 'operational') {
      tempRwyData.atts = [...attachment]
    } else {
      tempRwyData.atts = []
    }

    setRunwayArray(tempRwyData)
    handleEditOk()
    //rwyForm.resetFields()
    //setAnalysisMethod(false)
    //setRwy('')
    //setFormLock(false)
  }

  const onChangeForm = () => {
    const o = rwyForm.getFieldsValue()
    setRwy(o.rwy)
    console.log(o)
    if (o['rwy-analysis-method-select'] === 'data' || o['rwy-analysis-method-select'] === 'desktop' || o['rwy-analysis-method-select'] === 'operational') {
      setAnalysisMethod(true)
    } else {
      setAnalysisMethod(false)
    }
  }

  const input = [
    {
      name: 'rwy',
      label: 'Runway code',
      placeholder: '',
      type: 'input',
    },
  ]

  const setAttachmentsTable = (i) => {
    setAttachment([...attachment, i])
  }

  const deleteAttachments = (attId) => {
    let tempAtt = []
    tempAtt = [...attachment].filter(item => item.id !== attId)
    setAttachment(tempAtt)
  }

  const inputs = [...input, ...rwyQ]

  return (
    <>
      <Form form={rwyForm} name="runway-edit-form" onFinish={onFinish} onFieldsChange={onChangeForm} disabled={formLock}>
        {inputs.map((z) => <FormGen {...z} key={`${z.name}_key`} />)}
        <Divider />
        {analysisMethod ? <>
          <p>Analysis attachments</p>
          <FileHandler apt={apt} act={act} attachment={attachment} setAttachmentsTable={setAttachmentsTable} deleteAttachments={deleteAttachments} rwy={rwy} />
        </> : <></>}
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

const RunwaysTable = ({ data, setRunwayArray, deleteRunway, act, apt }) => {
  const [allAppr, setAllAppr] = useState([])
  const [loading, setLoading] = useState(true)
  const [selected, setSelected] = useState(false)
  const [tableDate, setTableData] = useState([])
  const [openCollapse, setOpenCollapse] = useState([])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isActiveModalVisible, setIsActiveModalVisible] = useState(false)
  const [isNewModalVisible, setIsNewModalVisible] = useState(false)
  const [editApproval, setEditApproval] = useState({})
  const [activeApproval, setActiveApproval] = useState({})
  const [newData, setNewData] = useState(false)

  const rwyQ = useRecoilValue(RWYQuestions)

  useEffect(() => {
    setLoading(true)
    const q = [...rwyQ]
    const rwyQt = {}
    for (const iterator of q) {
      rwyQt[iterator.name] = {}
      for (const j of iterator.options) {
        rwyQt[iterator.name][j.value] = j.display
      }
    }
    console.log(rwyQt)
    const tRwys = data.map((item) => {
      console.log(item['rwy-analysis-method-select'])
      const k = rwyQt['rwy-cat-select'][item['rwy-cat-select']]
      const analysis = rwyQt['rwy-analysis-method-select'][item['rwy-analysis-method-select']]
      console.log(k)
      console.log(analysis)

      return { ...item, 'cat': k, 'analysis': analysis }
    })
    setTableData(tRwys)
    setLoading(false)

  }, [data])

  const viewApproval = (i) => {
    setSelected(false)
    console.log(i)
    setSelected(i)
  }

  const showEditModal = (i) => {
    setEditApproval(i)
    setIsModalVisible(true)
  }

  const handleEditOk = async () => {
    setEditApproval({})
    setIsModalVisible(false)

  }

  const handleEditCancel = async () => {
    setEditApproval({})
    setIsModalVisible(false)

  }

  const showActiveModal = (i) => {
    setActiveApproval(i)
    setIsActiveModalVisible(true)
  }

  const handleActiveOk = async () => {
    setActiveApproval({})
    setIsActiveModalVisible(false)

  }

  const handleActiveCancel = async () => {
    setActiveApproval({})
    setIsActiveModalVisible(false)
  }

  const showNew = (i) => {
    setNewData(true)
    setIsNewModalVisible(true)
  }

  const handleNewOk = async () => {
    setNewData(false)
    setIsNewModalVisible(false)
  }

  const handleNewCancel = async () => {
    setNewData(false)
    setIsNewModalVisible(false)
  }

  const columns = [
    {
      title: 'Runway',
      dataIndex: 'rwy',
      key: 'rwy',
    },
    {
      title: 'Category',
      dataIndex: 'cat',
      key: 'cat',
    },
    {
      title: 'Analysis method',
      dataIndex: 'analysis',
      key: 'analysis'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return (
          <>
            <Button style={{ marginRight: '5px' }} type='primary' onClick={() => { showEditModal(record) }} size="small">Edit</Button>
            <Popconfirm
              title="Delete the runway"
              description="Are you sure to delete this runway?"
              onConfirm={() => {deleteRunway(record.key)}}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger>Delete</Button>
            </Popconfirm>
          </>
        )
      },
    },
  ]

  const handleCollapse = () => {
    if (openCollapse.length === 0) {
      setOpenCollapse([1])
    } else {
      setOpenCollapse([])
    }
  }

  return (
    <>
      {
        loading ? <Empty />
          : (
            <>
              <Button type='primary' onClick={() => { showNew() }} >Add</Button>
              <Table rowkey="key" columns={columns} dataSource={tableDate} size="small" pagination={{ pageSize: 10 }} />
            </>
          )
      }

      <Modal
        title={'Edit runway'}
        open={isModalVisible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        maskClosable={false}
        width="50%"
        style={{ top: 25 }}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleEditCancel}>
            Close
          </Button>,
        ]}
      >
        <EditForm data={editApproval} setRunwayArray={setRunwayArray} handleEditOk={handleEditOk} apt={apt} act={act} />
      </Modal>

      <Modal
        title={`Active ${activeApproval.id}`}
        open={isActiveModalVisible}
        onOk={handleActiveOk}
        onCancel={handleEditCancel}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleActiveCancel}>
            Close
          </Button>,
        ]}
      >
        {activeApproval.id ? <ApprovalForm data={activeApproval} /> : <></>}
      </Modal>

      <Modal
        title="New runway"
        open={isNewModalVisible}
        onOk={handleNewOk}
        onCancel={handleNewCancel}
        maskClosable={false}
        destroyOnClose={true}
        width="50%"
        style={{ top: 25 }}
        footer={[
          <Button key="back" onClick={handleNewCancel}>
            Close
          </Button>,
        ]}
      >
        {newData ? <NewForm setRunwayArray={setRunwayArray} handleNewOk={handleNewOk} apt={apt} act={act}/> : <></>}
      </Modal>
    </>
  )
}

export default RunwaysTable
