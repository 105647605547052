import { useEffect, useState } from 'react'
import {
  useLocation,
  Navigate,
  Outlet,
} from 'react-router-dom'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { authState } from '../atoms/AuthState'

import apiService from '../services/apiService'
import { initState } from '../atoms/initAtom'

import { Spin } from 'antd'

const ProtectedRoute = ({ auth }) => {
  const authStateStatus = useRecoilValue(authState)
  const location = useLocation()

  // const initstatus = useRecoilValue(initState)
  const setInitState = useSetRecoilState(initState)
  const setAuthState = useSetRecoilState(authState)
  const [loadingStatus, setLoadingStatus] = useState(true)

  const bgService = async () => {
    try {
      const currentSession = await auth.currentSession()

      const accessToken = currentSession.getAccessToken()

      const expirationTime = accessToken.getExpiration()

      // console.log('expirationTime', expirationTime)

      const o = new Date( Date.now() + 1000 * (60 * 15) ).getTime() / 1000

      if (expirationTime < o) {
        console.log('Renew auth token!')
        const t = await auth.currentAuthenticatedUser({ bypassCache: true })
        setAuthState({
          auth: true,
          signInUserSession: { ...t.signInUserSession },
        })
      }
      await apiService.init()
    } catch (error) {
      console.error(error)
      setAuthState({
        auth: false,
        signInUserSession: {},
      })
    }
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      // console.log('Interval start')
      await bgService()
      // console.log('Interval stop')
    }, 180000)

    const init = async () => {
      try {
        const t = await auth.currentAuthenticatedUser({ bypassCache: true })
        setAuthState({
          auth: true,
          signInUserSession: { ...t.signInUserSession },
        })

        const result = await apiService.init()
        console.log('init:', result)
        setInitState(result)
        setLoadingStatus(false)
      } catch (error) {
        console.error(error)
        setAuthState({
          auth: false,
          signInUserSession: { },
        })
      }
    }
    init()

    return () => clearInterval(interval)
  }, [])

  if (!authStateStatus.auth) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  if (loadingStatus) {
    return (
      <div className="site-layout-background" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin tip="Loading..." size="large">
        </Spin>
      </div>)
  }

  return <Outlet />
}

export default ProtectedRoute
