import { atom, selector } from 'recoil'

const initState = atom({
  key: 'initState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
})

const ACTList = selector({
  key: 'FilteredInitListACT',
  get: ({ get }) => {
    const init = get(initState)

    return (init.aircraftTypes)
  },
})

const APTList = selector({
  key: 'FilteredInitListAPT',
  get: ({ get }) => {
    const init = get(initState)

    // const tempApt = init.filter((i) => i.FA === 'APT')

    // const arrayUniqueByKey = [...new Map(tempApt.map((item) => [item.FB, { icao: item.FB, name: item.FD }])).values()]
    return (init.airport)
  },
})

const APTQuestions = selector({
  key: 'FilteredInitListAPTQuestions',
  get: ({ get }) => {
    const init = get(initState)

    //const tempApt = init.filter((i) => i.FA === 'RECSPEC' && i.FB === 'APT')
    // console.log(tempApt)
    if (init) {
      console.log('init', init)
      const recspec = init.recspec.filter((i) => i.target === 'APT')
      const u = recspec.map((g) => (JSON.parse(g.jsonSpec)))
      // const u = init.recspec
      console.log(u)

      const groupsTemp = u.filter((g) => (g.group))
      console.info(groupsTemp)

      const groups = {}

      // eslint-disable-next-line array-callback-return
      groupsTemp.map((a, index) => {
        groups[a.groupname] = { key: index + 1, ...a.group, items: [] }
      })

      console.info('groups', groups)

      const groupItemSort = [...u].sort((a, b) => (a.index > b.index) ? 1 : -1)

      // eslint-disable-next-line array-callback-return
      groupItemSort.map((g, index) => {
        if (g.groupname) {
          const y = { ...g, key: index + 1 }
          groups[g.groupname].items.push(y)
        }
      })
      console.info('groups', groups)
      const j = (Object.keys(groups).map((h) => (groups[h])))

      //const o = [...u, ...j].map((f) => (JSON.parse(f.jsonSpec)))
      const sorted = [...u, ...j]
      const c = sorted.sort((a, b) => (a.index > b.index) ? 1 : -1)

      console.log('APTQuestions', c)
      return (c)
    }
    return []
  },
})

const RWYQuestions = selector({
  key: 'FilteredInitListRWYQuestions',
  get: ({ get }) => {
    const init = get(initState)

    //const tempApt = init.filter((i) => i.FA === 'RECSPEC' && i.FB === 'APT')
    // console.log(tempApt)
    if (init) {
      console.log('init', init)
      const recspec = init.recspec.filter((i) => i.target === 'RWY')
      const u = recspec.map((g) => (JSON.parse(g.jsonSpec)))
      // const u = init.recspec
      console.log(u)

      const groupsTemp = u.filter((g) => (g.group))
      console.info(groupsTemp)

      const groups = {}

      // eslint-disable-next-line array-callback-return
      groupsTemp.map((a, index) => {
        groups[a.groupname] = { key: index + 1, ...a.group, items: [] }
      })

      console.info('groups', groups)

      const groupItemSort = [...u].sort((a, b) => a.index - b.index)

      // eslint-disable-next-line array-callback-return
      groupItemSort.map((g, index) => {
        if (g.groupname) {
          const y = { ...g, key: index + 1 }
          groups[g.groupname].items.push(y)
        }
      })
      console.info('groups', groups)
      const j = (Object.keys(groups).map((h) => (groups[h])))

      //const o = [...u, ...j].map((f) => (JSON.parse(f.jsonSpec)))
      return ([...u, ...j])
    }
    return []
  },
})

export {
  initState, ACTList, APTList, APTQuestions, RWYQuestions
}
