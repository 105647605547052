import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import './index.css'
// import { PublicClientApplication } from "@azure/msal-browser"
// import { msalConfig } from "./authConfig"
// import reportWebVitals from './reportWebVitals';

import {
  RecoilRoot,
} from 'recoil'

import App from './App'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>
  ,
)
