/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'

import {
  Table, Descriptions, Form, Button, Modal,
  DatePicker, notification, message, Upload,
  Divider, Empty, Radio, Row, Col, Collapse,
  Typography,
  Avatar,
} from 'antd'

import apiService from '../services/apiService'
import notificationHandler from './notificationHandler'
import { formatISO } from 'date-fns'


const { Panel } = Collapse
const { Title } = Typography

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
}

const ApprovalForm = ({ data, active, handleActiveOk }) => {
  const [form] = Form.useForm()
  const [formLock, setFormLock] = useState(false)

  useEffect(() => {
    form.setFieldsValue({ ...data })
    setFormLock(false)
  }, [data])

  const onFinish = async (values) => {
    try {
      setFormLock(true)
      //console.log(values.approvedAt.toISOString())
      const postData = { ...data }
      delete postData.key
      if (active === true) {
        //postData.approvedAt = values.approvedAt.toISOString()
        postData.approvedAt = formatISO(new Date())

      } else {
        //postData.revokedAt = values.approvedAt.toISOString()
        postData.revokedAt = formatISO(new Date())
      }
      console.log(postData)
      const res = await apiService.updateApproval(postData)
      console.log('res', res)
      notificationHandler('success', 'Success!')
      handleActiveOk()
    } catch (error) {
      console.error(error)
      notificationHandler('error', 'Failed!')

    }

  }

  return (
    <Form form={form} onFinish={onFinish} disabled={formLock}>
      <Form.Item >
        {active ? <p>Do you want to activate draft {data.id}?</p> : <p>Do you want to revoke version {data.version}</p>}
        <Button type="primary" htmlType="submit">
          {active ? 'Activate' : 'Revoke'}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ApprovalForm
