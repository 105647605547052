import { atom } from 'recoil'

const navState = atom({
  key: 'navState', // unique ID (with respect to other atoms/selectors)
  default: {
    menu: 1,
    submenu: '',
  }, // default value (aka initial value)
})

export default navState
