/* eslint-disable default-case */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import {
  Table, Button, Modal, Form, Input,
} from 'antd'
// import { SearchOutlined } from '@ant-design/icons'

import navState from '../atoms/NavState'
import apiService from '../services/apiService'

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
}

const EditForm = ({ data }) => {
  // console.log('data', data)
  const [form] = Form.useForm()
  const [formLock, setFormLock] = useState(false)

  useEffect(() => {
    form.setFieldsValue({ ...data })
    setFormLock(false)
  }, [data])

  const onFinish = async (values) => {
    setFormLock(true)
    console.log(values)
    const res = await apiService.updateAircraft(values)
    console.log('res', res)
  }

  return (
    <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
      <Form.Item
        name="icao"
        label="ICAO code"
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="name"
        label="Name"
      >
        <Input disabled={formLock} />
      </Form.Item>
      <Form.Item
        name="iata"
        label="IATA code"
      >
        <Input disabled={formLock} />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

const NewForm = () => {
  // console.log('data', data)
  const [form] = Form.useForm()
  const [formLock, setFormLock] = useState(false)

  const onFinish = async (values) => {
    setFormLock(true)
    console.log(values)
    const res = await apiService.addAircraft(values)
    console.log('res', res)
  }

  return (
    <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
      <Form.Item
        name="icao"
        label="ICAO code"
      >
        <Input disabled={formLock} />
      </Form.Item>
      <Form.Item
        name="name"
        label="Name"
      >
        <Input disabled={formLock} />
      </Form.Item>
      <Form.Item
        name="iata"
        label="IATA code"
      >
        <Input disabled={formLock} />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

const AircraftPage = () => {
  const [airports, setAirports] = useState([])
  const setNavStateStatus = useSetRecoilState(navState)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [newAircraftVisible, setNewAircraftVisible] = useState(false)
  const [editAircraft, setEditAircraft] = useState({})

  useEffect(() => {
    setNavStateStatus({ menu: 4, submenu: 'sub1' })

    const i = async () => {
      try {
        const res = await apiService.getAllAircraft()
        console.log('getAllAircraft', res)
        const tempData = res.map((v, index) => ({ ...v, key: index + 1 }))
        console.log(tempData)
        setAirports(tempData)
        // console.log(airports)
      } catch (error) {
        console.error(error)
      }
    }
    i()
  }, [])

  const showModal = (i) => {
    setEditAircraft(i)
    setIsModalVisible(true)
  }

  const showNewAircraftModal = () => {
    setNewAircraftVisible(true)
  }

  const handleOk = () => {
    setEditAircraft({})
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setEditAircraft({})
    setIsModalVisible(false)
  }

  const handleOkNewAircraft = () => {
    setNewAircraftVisible(false)
  }

  const handleCancelNewAircraft = () => {
    setNewAircraftVisible(false)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ICAO code',
      dataIndex: 'icao',
      key: 'icao',
    },
    {
      title: 'IATA code',
      dataIndex: 'iata',
      key: 'iata',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type="primary" key={`${record.icao}_edit_button`} onClick={() => { showModal(record) }}>
          Edit
          {' '}
          {record.icao}
        </Button>
      ),
    },
  ]

  if (airports.length === 0) {
    return (
      <>Loading</>
    )
  }

  return (
    <>
      <Button key="new_button" type="primary" onClick={showNewAircraftModal} style={{ float: 'right', marginBottom: '4px' }}>
        New aircraft
      </Button>
      <Table rowkey="idACT" columns={columns} dataSource={airports} />
      <Modal
        title={`Edit aircraft ${editAircraft.icao}`}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        {editAircraft.icao ? <EditForm data={editAircraft} /> : <></>}
      </Modal>
      <Modal
        title="New aircraft"
        open={newAircraftVisible}
        onOk={handleOkNewAircraft}
        onCancel={handleCancelNewAircraft}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleCancelNewAircraft}>
            Close
          </Button>,
        ]}
      >
        <NewForm />
      </Modal>
    </>
  )
}

export default AircraftPage
