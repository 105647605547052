import { Input, Form } from 'antd'

const { TextArea } = Input

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 12,
  },
}


const TextOma = ({
  name, label, disabled,
}) => {
  console.log()
  return (
    <Form.Item
      {...formItemLayout}
      label={label}
      name={name}
    >
      <TextArea placeholder="" autoSize={{ minRows: 2 }} disabled={disabled} />
    </Form.Item>
  )
}

export default TextOma
